<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        size="lg"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nQuestionnaire"
                            label-for="filter-questionnaire"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-questionnaire'"
                                ref="questionnaireMultiselect"
                                v-model="filterModal.questionnaire"
                                :service="'questionnaires'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            label-for="filter-business-unit"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="filterModal.business_unit"
                                :service="'business-units'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nFlag"
                            label-for="filter-flag"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-flag'"
                                ref="flagMultiselect"
                                v-model="filterModal.flags"
                                :service="'pos-flags'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChain"
                            label-for="filter-chain"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-chain'"
                                ref="chainMultiselect"
                                v-model="filterModal.chains"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRegionals"
                            label-for="filter-regional"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-regional'"
                                ref="regionalMultiselect"
                                v-model="filterModal.regionals"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nPos"
                            label-for="filter-pos"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-pos'"
                                ref="posMultiselect"
                                v-model="filterModal.point_of_sale"
                                :service="'pos'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nUser"
                            label-for="filter-user"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-user'"
                                ref="userMultiselect"
                                v-model="filterModal.users"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nStateAndCity"
                            label-for="filter-state-city"
                            class="label-pdv">
                            <treeselect
                                :id="'filter-state-city'"
                                ref="filterStateCity"
                                v-model="filterModal.statesAndCities"
                                :options="statesWithCities"
                                :multiple="true"
                                :placeholder="getI18n('PLACEHOLDERS', 'type_to_search')"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRole"
                            class="label-pdv"
                            label-for="filter-role"
                        >
                            <multiSelectWithService
                                :id="'filter-role'"
                                ref="roleMultiselect"
                                v-model="filterModal.roles"
                                :service="'roles'"
                                label="alias"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nFrequency"
                            label-for="filter-started-at-date-time"
                            class="label-pdv">
                            <date-range-picker
                                :value.sync="filterModal.startedAtDateTime"
                                :max-date="new Date()"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
    import swalFeedback from '@src/mixins/swalFeedback.vue'
    import validationMixin from '@src/mixins/validation.vue'
    import FormModal from '@/src/components/FormModal.vue'
    import SaveButton from '@/src/components/SaveButton'
    import ClearFilterButton from '@/src/components/ClearFilterButton'
    import cloneObject from '@utils/clone-object'
    import DateRangePicker from '@/src/components/DateRangePicker'
    import multiSelectWithService from '@src/components/multiSelectWithService'
    import csvFields from '@src/mixins/csvFields'
    import statesWithCities from '@src/assets/resources/statesWithCities'
    import Treeselect from '@riophae/vue-treeselect'

    const i18nKey = 'QUESTIONNAIRE_ANSWERS'
    const i18nCommon = 'COMMON'

    export default {
        components: {
            FormModal,
            DateRangePicker,
            SaveButton,
            ClearFilterButton,
            multiSelectWithService,
            Treeselect,
        },
        mixins: [
            validationMixin,
            swalFeedback,
            csvFields,
        ],
        props: {
            handleSubmit: {
                type: Function,
                required: true,
                default: function () {},
            },
        },
        data() {
            return {
                i18nKey: i18nKey,
                i18nCommon: i18nCommon,
                statesWithCities: statesWithCities,
                filterModal: {},
                submitLoading: false,
                parameters: {
                    with_admin: true,
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                },
                usersParameters: {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    active: true,
                },
            }
        },
        validations: {},
        computed: {
            i18nQuestionnaire() {
                return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
            },
            i18nBusinessUnit() {
                return this.getI18n(i18nCommon, 'business_unit')
            },
            i18nFlag() {
                return this.getI18n('POS', 'TITLES.flag')
            },
            i18nChain() {
                return this.getI18n('POS', 'TITLES.chain')
            },
            i18nRegionals() {
                return this.getI18n('POS', 'TITLES.regional')
            },
            i18nPos() {
                return this.getI18n('POS', 'TITLES.pos')
            },
            i18nUser() {
                return this.getI18n('USERS', 'TITLES.user')
            },
            i18nStateAndCity() {
                return this.getI18n(i18nCommon, 'state_and_city')
            },
            i18nStartedAt() {
                return this.getI18n(i18nKey, 'FIELDS.started_at_date_time')
            },
            i18nEndedAt() {
                return this.getI18n(i18nKey, 'FIELDS.ended_at_date_time')
            },
            i18nRole() {
                return this.getI18n('ROLES', 'TITLES.role')
            },
            i18nFrequency() {
                return this.getI18n('WORKSCHEDULES', 'TITLES.frequency')
            },
        },
        methods: {
            showModal(previousFilters) {
                this.filterModal = cloneObject(previousFilters);
                this.submitLoading = false
                this.$refs.formModal.show()
            },
            cleanModal() {
                this.filterModal = Object.assign(this.filterModal, {
                    questionnaire: [],
                    startedAtDateTime: {},
                    endedAtDateTime: {},
                    business_unit: [],
                    point_of_sale: [],
                    users: [],
                    flags: [],
                    regionals: [],
                    chains: [],
                    statesAndCities: [],
                })
            },
            handleHide() {
                if (!this.submitLoading) {
                    this.cleanModal();
                }
            },
            handleOk() {
                if (this.submitLoading) {
                    return;
                }

                this.submitLoading = true;
                this.$props.handleSubmit(this.filterModal);
                this.$refs.formModal.hide()
            },
            async handleClearFilter() {
                this.cleanModal()
            }
        },
    }
</script>
